import {useContext} from 'react';

import { ExcelContext } from "../helper/ExcelProvider";
import HeptagonNav from "../components/qform/HeptagonNav";

function PageCheck(props){
  const {sheets} = useContext(ExcelContext);

  return <div className="d-lg-flex">
            <HeptagonNav data={sheets}/>
         </div> 
}

export default PageCheck;