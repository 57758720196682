
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Routes, Route, Link } from "react-router-dom";

import { RouterProvider } from "react-router-dom";
import { HashRouter } from 'react-router-dom';

import './custom.css';

import router from "./routes";
//import { AuthProvider } from './components/secure/AuthProvider';
import { ExcelProvider } from './helper/ExcelProvider';

import Header from './components/Header';
import PageCheck from './pages/PageCheck';

/*!
  container definied the total width
  col - define the width and centralized hexagram

function App(){
  return <ExcelProvider>
              <RouterProvider router={router} />
          </ExcelProvider>
}
 */
function App(){
  return <ExcelProvider>
    <div className='container-fluid'>
      <Header />

      <HashRouter>
        <Routes>
          <Route path="/" element={<PageCheck />} />
        </Routes>
      </HashRouter>
      </div>
  </ExcelProvider>
}

export default App;
