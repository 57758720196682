import { useState, useRef } from "react"

function    QuestionFree(props){
    let _id = "qfree" + props._nr;
    const onSave = (e) =>{
        console.log(e.target.value);
        props.updateData(props.nr,e.target.value);
    }

    return <div className='row'>
        <div className='col-lg-6'>
            {props.question}
        </div>
        <div className='col-lg-5 offset-lg-1'>
            <input type="text" className="form-control" id={_id} onBlur={onSave} defaultValue={props.val} ></input>
        </div>
    </div>
}
export default QuestionFree