/*!
    info bar component. It's used either to provide information to user such as
    successful, error, warning, hints
*/
import {useState} from 'react';
import {Alert} from 'react-bootstrap';

function Infobar(props){
    const [show, setShow] = useState(false);
    const [leakStore, setLeakStore] = useState(0);

    const delay = (ms) => {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    const showStatus = async () => {
        if ( show === false){
            setShow(true);
            await delay(2000);
            setShow(false); 
              
            if ( props.cbFinish !== undefined){
                props.cbFinish();
            }         
        }
    }
    if ( props.show !== leakStore){
        setLeakStore(props.show);

        if (props.show > 0){
            showStatus();
        }
    }
    
    return <>
        { show ? (
                <Alert  className="mb-2"
                    variant={props.info[props.show].type}
                    onClose={() => setShow(false)}
                    dismissible 
                >
                    {props.info[props.show].text}
                </Alert>
            ):(
                <div />
            )
        }
    </>
}
export default Infobar;