/*!
    side plugin for the check plugin
*/
import {useState} from 'react';

import Infobar from "../Infobar";
import QFormFile from "./QFormFile";
import {getQFormFileInfoList} from "./QFormFile";
import { QFormResult,getQFormResultInfoList } from "./QFormResult";


function QFormSidePlugin(){
    const [infoShow, setInfoShow] = useState(0);
    const [infoIdx, setInfoIdx] = useState(0);

    const infoList = [getQFormFileInfoList, getQFormResultInfoList];

    const delay = (ms) => {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    const returnInfo = async(_objIdx, _retVal) =>{
        setInfoIdx(_objIdx);
        setInfoShow(_retVal);
        await delay(2000);
        setInfoShow(0);
    }

    return <>
        <h6>Digital check </h6>
        <Infobar show={infoShow} info={infoList[infoIdx]}/>
        <ul className="list-inline">
        <li className="list-inline-item "><QFormFile retObj='0' returnInfo={returnInfo}/></li>
        <li className="list-inline-item"><QFormResult retObj='1' returnInfo={returnInfo}/></li>
        </ul>
        <hr />
    </>
}
export default QFormSidePlugin;