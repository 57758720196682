import {useContext} from "react";
//import { NavLink,useLocation } from "react-router-dom";

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';

import Navbar from 'react-bootstrap/Navbar';
//import Nav from 'react-bootstrap/Nav';
import Offcanvas from 'react-bootstrap/Offcanvas';

import jsonPack from '../../package.json';

import { ExcelContext } from "../helper/ExcelProvider";

import QFormSidePlugin from "./qform/QFormSidePlugin";

console.log(jsonPack);

function        Header(props){
  const {sheets} =  useContext(ExcelContext);
  const expand   =  false;

  return ( <>
    <Navbar expand={expand} className="bg-body-tertiary mb-3">
        <Container fluid>
          <Navbar.Brand href="./">
            <img src="./42fusionWhite.png" height="64" width="64" className="px-0" alt="logo"></img>
          </Navbar.Brand>
          <h3>{sheets.customer[0].Company}<span className="ms-4 fs-6">{sheets.meta[0].CheckType} Check</span></h3>       
    
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
              </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
              <Form className="d-flex">
               <Form.Control
                 type="search"
                 placeholder="Search"
                 className="me-2"
                 aria-label="Search"
               />
               <Button variant="outline-primary">Search</Button>
             </Form>
             <hr />
             <QFormSidePlugin />
             <span className="fs-6">Version {jsonPack.version}</span>
              </Offcanvas.Body>
         </Navbar.Offcanvas>
       </Container>
     </Navbar>
 </>
);
}
// className="nav-link"
export default Header;