/*!
   helper function to manipulate excel worksheets.
*/
const ExcelJS = require('exceljs');

function WorkbookCreate(_Author){
   const workbook = new ExcelJS.Workbook();
   
   workbook.creator = _Author;
   workbook.created = new Date();

   workbook.views = [
      {
        x: 0, y: 0, width: 10000, height: 20000,
        firstSheet: 0, activeTab: 1, visibility: 'visible'
      }
   ];
   // .. later create, modified,etc.
   return workbook;
}

function WorkSheetCreate(_workBook, _name, _tabColor ){
   const sheet = _workBook.addWorksheet(
      _name,
      {
         properties:{tabColor:{argb:_tabColor}},
         views: [{showGridLines: false}],
      }
   )

   return sheet;
}

function SheetHeaderRow(_sheet, _jsonRow){

}

function sheetAutoWidthColumn(worksheet){
   worksheet.columns.forEach(function (column, i) {
      let maxLength = 0;
      column["eachCell"]({ includeEmpty: true }, function (cell) {
          var columnLength = cell.value ? cell.value.toString().length : 10;
          if (columnLength > maxLength ) {
              maxLength = columnLength;
          }
      });
      column.width = maxLength < 10 ? 10 : maxLength;
  });
}

async function WorkbookStore(_book, filename){
   const buffer = await _book.xlsx.writeBuffer();
   let blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
   let link = document.createElement('a');
   link.href = URL.createObjectURL(blob);
   link.download = filename;
   link.click();
   URL.revokeObjectURL(link.href);

}

function    _sheetcreate( _sheet, obj_resSheet){
   const hdrRow =["Nr","Frage","Antwort"];
   _sheet.addRow(hdrRow);
   const Rows = obj_resSheet.map((e)=>{return [e.Nr,e.Frage,e.Value]})
   
   _sheet.addRows(Rows);
   sheetAutoWidthColumn(_sheet);
}

async    function    excelCheck( _res, _filename, _author){
   const workbookName   = _filename + ".xlsx";
   const sheets         = [
      {name:"part1", color: 'FFFF00'},
      {name:"part2", color: 'CCFF00'},
      {name:"part3", color: 'FF0000'},

      {name:"part4", color: '90EE90'},
      {name:"part5", color: '00FF00'},
      {name:"part6", color: 'C0C0FF'},
     
      {name:"part7", color: '79CDCD'},   
   ];

   const book = WorkbookCreate(_author);
   const _name = [
      'part1',
      'part2',
      'part3',
      'part4',
      'part5',
      'part6',
      'part7'
   ];

   sheets.forEach((e,idx)=>{
      const sheet = WorkSheetCreate( book, e.name, e.color);
      _sheetcreate(sheet, _res[_name[idx]]); //.part[idx]);
      if(idx == sheets.length-1){
         WorkbookStore( book, workbookName);
      }
   })
}



async    function _cliTest(){
   const WorkBookName = 'TestBook.xlsx';
   const Sheets = [
      {name:"part1", color: 'FFFF00'},
      {name:"part2", color: 'CCFF00'},
      {name:"part3", color: 'FF0000'},

      {name:"part4", color: '90EE90'},
      {name:"part5", color: '00FF00'},
      {name:"part6", color: 'C0C0FF'},
     
      {name:"part7", color: '79CDCD'},
     

   ];

   const _book = WorkbookCreate("Kelbch");
   Sheets.forEach((e)=>{
      WorkSheetCreate( _book, e.name, e.color);
   })

   const worksheet = _book.getWorksheet('part1');
   worksheet.columns = [
      { header: 'Id', key: 'id', width: 10 },
      { header: 'Name', key: 'name', width: 32 },
      { header: 'D.O.B.', key: 'DOB', width: 10, outlineLevel: 1 }
    ];
    //_book.xlsx.writeFile(WorkBookName);
    
    //fs.writeFileSync("test.xlsx",buffer.toString('base64'));
    WorkbookStore( _book, "test.xlsx");
}

module.exports = {excelCheck};