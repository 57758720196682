import { useState, useRef } from "react"

function    QFormNote(props){
    
    const onSave = (e) => {
        props.UpdateNote(e.target.value)
    }
    return <>
         <label className="form-label">Notizen </label>
        <textarea className="form-control" id="textAreaExample1" rows="4" defaultValue={props.val}  onBlur={onSave}></textarea>
    </>

}
export default QFormNote;