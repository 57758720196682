const excelToJson   = require('convert-excel-to-json');
const xlsx          = require('json-as-xlsx');

function        onCompletedDownload(){

}

function        convertJson2Excel(_data, _meta){
    let settings = {
        fileName: `${_meta.FirmenId}-${_meta.CheckType}-result`, // Name of the resulting spreadsheet
        extraLength: 3, // A bigger number means that columns will be wider
        writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
        writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
        RTL: false, // Display the columns from right-to-left (the default value is false)
    };

    let data = [
        {
            sheet: "meta",
            columns: [
              { label: "CheckType", value: "CheckType" }, // Top level data
              { label: "Version", value: "Version" }, // Custom format
              { label: "Description", value: "Description" }, // Run functions
            ],
            content: _data.meta,
        },
        {
            sheet: "customer",
            columns:[
                { label: "companyid", value: "companyid"},
                { label: "Company", value: "Company"},
                { label: "Contact", value: "Contact"},
                { label: "Devision", value: "Devision"},
                { label: "Email", value: "Email"},
                { label: "Phone", value: "Phone"},
            ],
            content: _data.customer
        },
        {
            sheet: "modules",
            columns:[
                { label: "name", value: "name"},
                { label: "completion", value: "completion"},
                { label: "visible", value: "visible"},
                { label: "ref", value: "ref"},
                { label: "note", value: "note"},
                { label: "recommendation", value: "recommendation"}
                
                
            ],
            content: _data.modules
        },
        {
            sheet: "part1",
        
            columns:[
                { label: "Nummer", value: "Nr"},
                { label: "Frage", value: "Frage"},
                { label: "Value", value: "Value"},
                { label: "FType", value: "FType"},
                { label: "Choice", value: "Choice"},
                { label: "Answered", value: "Answered"}

            ],
            content: _data.part1
        },
        {
            sheet: "part2",
        
            columns:[
                { label: "Nummer", value: "Nr"},
                { label: "Frage", value: "Frage"},
                { label: "Value", value: "Value"},
                { label: "FType", value: "FType"},
                { label: "Choice", value: "Choice"},
                { label: "Answered", value: "Answered"}
            ],
            content: _data.part2
        },
        {
            sheet: "part3",
        
            columns:[
                { label: "Nummer", value: "Nr"},
                { label: "Frage", value: "Frage"},
                { label: "Value", value: "Value"},
                { label: "FType", value: "FType"},
                { label: "Choice", value: "Choice"},
                { label: "Answered", value: "Answered"}
            ],
            content: _data.part3
        },
        {
            sheet: "part4",
        
            columns:[
                { label: "Nummer", value: "Nr"},
                { label: "Frage", value: "Frage"},
                { label: "Value", value: "Value"},
                { label: "FType", value: "FType"},
                { label: "Choice", value: "Choice"},
                { label: "Answered", value: "Answered"}
            ],
            content: _data.part4
        },
        {
            sheet: "part5",
        
            columns:[
                { label: "Nummer", value: "Nr"},
                { label: "Frage", value: "Frage"},
                { label: "Value", value: "Value"},
                { label: "FType", value: "FType"},
                { label: "Choice", value: "Choice"},
                { label: "Answered", value: "Answered"}
            ],
            content: _data.part5
        },
        {
            sheet: "part6",
        
            columns:[
                { label: "Nummer", value: "Nr"},
                { label: "Frage", value: "Frage"},
                { label: "Value", value: "Value"},
                { label: "FType", value: "FType"},
                { label: "Choice", value: "Choice"},
                { label: "Answered", value: "Answered"}
            ],
            content: _data.part6
        },
        {
            sheet: "part7",
        
            columns:[
                { label: "Nummer", value: "Nr"},
                { label: "Frage", value: "Frage"},
                { label: "Value", value: "Value"},
                { label: "FType", value: "FType"},
                { label: "Choice", value: "Choice"},
                { label: "Answered", value: "Answered"}
            ],
            content: _data.part7
        },
        {
            sheet: "config",
        
            columns:[
                { label: "qtype1", value: "qtype1"},
                { label: "qtype2", value: "qtype2"},
                { label: "qtype3", value: "qtype3"},
                { label: "qtype4", value: "qtype4"}

            ],
            content:[
                {qtype1:"yesno", qtype2:"scale",qtype3:"open",qtype4:"choice"}
            ]
        }
    ];

    xlsx(data, settings, onCompletedDownload)       
}

function        convertExcel2Json(_file){
    return excelToJson({
        source: _file,
        header:{
            rows: 1
        },
        sheets:[{
            name: 'meta',
            columnToKey:{
                A: 'CheckType',
                B: 'Version',
                C: 'Description'
            }
        },
        {
            name: 'customer',
            columnToKey: {
                A: 'companyId',
                B: 'Company',
                C: 'Contact',
                D: 'Devision',
                E: 'Email',
                F: 'Phone'
            }
        },
        {
            name: 'modules',
            columnToKey: {
            A: 'name',
            B: 'completion',
            C: 'note',
            D: 'visible',
            E: 'ref',
            F: 'note',
            G: 'recommendation'
            }
        },
        {
            name: 'part1',
            columnToKey: {
                A: 'Nr',
                B: 'Frage',
                C: 'Value',
                D: 'FType',
                E: 'Choice',
                F: 'Answered'
            }
        },{
            name: 'part2',
            columnToKey: {
                A: 'Nr',
                B: 'Frage',
                C: 'Value',
                D: 'FType',
                E: 'Choice',
                F: 'Answered'
            }
        },{
            name: 'part3',
            columnToKey: {
                A: 'Nr',
                B: 'Frage',
                C: 'Value',
                D: 'FType',
                E: 'Choice',
                F: 'Answered'
            }
        },{
            name: 'part4',
            columnToKey: {
                A: 'Nr',
                B: 'Frage',
                C: 'Value',
                D: 'FType',
                E: 'Choice',
                F: 'Answered'
            }
        },{
            name: 'part5',
            columnToKey: {
                A: 'Nr',
                B: 'Frage',
                C: 'Value',
                D: 'FType',
                E: 'Choice',
                F: 'Answered'
            }
        },{
            name: 'part6',
            columnToKey: {
                A: 'Nr',
                B: 'Frage',
                C: 'Value',
                D: 'FType',
                E: 'Choice',
                F: 'Answered'
            }
        },{
            name: 'part7',
            columnToKey: {
                A: 'Nr',
                B: 'Frage',
                C: 'Value',
                D: 'FType',
                E: 'Choice',
                F: 'Answered'
            }
        }]
    });    
}

module.exports = {convertExcel2Json,convertJson2Excel};