import { useState, useEffect } from "react"

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './ModalQForm.css';
import QuestionRanking from "./QuestionRanking";
import QuestionYesNoMatter from "./QuestionYesNoMatter";
import QuestionFree from "./QuestionFree";
import QFormNote from "./QFormNote";
import QuestionChoice from "./QuestionChoice";

function    QAItem(props){
    const onUpdate = (_nr,_val) =>{
        props.updateData(_nr,_val);
    }
    const   questionType = {
        "scale"  : <QuestionRanking nr={props.idx} question={props.el.Frage} low="0" high="10" val={props.el.Value} updateData={onUpdate} />,
        "yesno"  : <QuestionYesNoMatter nr={props.idx} question={props.el.Frage} val={props.el.Value} updateData={onUpdate} />,
        "open"   : <QuestionFree nr={props.idx} question={props.el.Frage} val={props.el.Value} updateData={onUpdate} />,
        "choice" : <QuestionChoice nr={props.idx} question={props.el.Frage} val={props.el.Value} choice={props.el.Choice} updateData={onUpdate} />
    };
    
    return (<div className="row">
            { questionType[props.el.FType]}
         <hr className="mt-1 mb-1 pt-2"/>
    </div>)
}

/*
    bearbeiten einer result list.
    answData = [
        {nr: , val: ,answeredFlag: },
        {nr: , val: ,answeredFlag: },
    ]
    update 
*/

function    ModalQAList(props){
    const [answerPackage, setAnswerPackage] = useState(
        props.data[props.part].map(el =>{ return {nr:el.Nr, val:"", answeredFlag:false} })
    );
    const [completionState, setCompletionState] = useState(0);

    let    _moduleId = props.data.modules.findIndex((el)=>{return el.ref === props.part ;});
    let    _package = {
                list: props.data,
                total:props.data[props.part].length,
                answered: props.data[props.part].filter(e => e.Answered === true).length,
                answers: props.data[props.part].map(el =>{ return {nr:el.Nr, val: "_none"}})
            };

    const onUpdateData = (_nr, _value)=>{
        let tmpPackage = answerPackage;

        let idx = tmpPackage.findIndex( el => el.nr === _nr);
        
        if ( idx !== -1){
            // entry found. let's enter new value
            tmpPackage[idx].val = _value;
            tmpPackage[idx].answeredFlag = true;    

            setAnswerPackage(tmpPackage);
            setCompletionState(Math.round((_package.answered/_package.total)*100));
        }
        
        if (idx !== -1){
            _package.answers[idx].val               = _value;
            _package.list[props.part][idx].Answered = true;
            
            _package.answered = _package.list[props.part].filter(e => e.Answered === true).length;
            setCompletionState(Math.round((_package.answered/_package.total)*100));
        } 
    }
    
    const onNoteUpdate = (note) =>{
        props.data.modules[_moduleId].note = note;
    }

    const onSave = (event) => {
        // enter all data into array
        console.log(_package);
        console.log(answerPackage);
        answerPackage.forEach( (elA)=>{
            let idx = props.data[props.part].findIndex(el => elA.nr === el.Nr );
            if (idx !== -1 && elA.answeredFlag === true ){
                props.data[props.part][idx].Value    = elA.val;
                props.data[props.part][idx].Answered = true;
            }
        }) 
        
        _package.answered = props.data[props.part].filter(e => e.Answered === true).length;

        props.data.modules[_moduleId].completion = Math.round( (_package.answered/_package.total)*100);
        
        props.onHide();
        
    };

    useEffect(() => {
        if ( answerPackage.length !== props.data[props.part].length){
            let tmpPackage = props.data[props.part].map(el =>{ return {nr:el.Nr, val:"", answeredFlag:false} })
            setAnswerPackage(tmpPackage);
        }
        setCompletionState(Math.round((_package.answered/_package.total)*100));
    },[completionState,_package.answered,_package.total])

    return ( <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                {props.data.modules[_moduleId].name}              
            </Modal.Title>
        </Modal.Header >
        <Modal.Body>
            <div className='row'>
                <div className='col-lg-3'>{props.data.customer.name}</div>
                <div className='col-lg-3 offset-lg-6'>completion {completionState}%</div>
                <hr className="mt-1 mb-1 pt-5"/>
        
            </div>
            <div className='row'>
        
            { props.data[props.part].map((el,index)=><QAItem el={el} key={index} idx={el.Nr} updateData={onUpdateData}/>)}
            </div>
            <QFormNote UpdateNote={onNoteUpdate}/>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={onSave} >Speichern</Button>
        </Modal.Footer>
    </Modal>)
    
}

export default ModalQAList;