
import React from 'react';
import { CircularProgressbar,buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import "./HeptagonNav.css"
import ModalQAList from './ModalQForm';

function HeptagonNav(props){
    const [modalShow, setModalShow] = React.useState(false);
    const [partId, setPartId] = React.useState("part1");
    const onTest = (e)=>{
        const id = e.currentTarget.getAttribute("id");
        setPartId(id);
        setModalShow(true);
    }; 
    // mx-auto
    if(props.data !== undefined){
    return (<div className='mx-auto'>
        <div className="heptnav">
            { props.data.modules.map( (el,idx) => {
                let _cl = "btn btn-transparent heptnode dot" + idx;
                let _id = "part" + (idx+1);
                return <button type="button" className={_cl} key={idx} id={_id} onClick = {onTest}>
                    <CircularProgressbar value={el.completion} text={el.name} 
                        styles={buildStyles({
                            // Rotation of path and trail, in number of turns (0-1)
                            // rotation: 0.25,
                        
                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                            strokeLinecap: 'butt',
                        
                            // Text size
                            textSize: '10px',
                        
                            // How long animation takes to go from one percentage to another, in seconds
                            pathTransitionDuration: 0.5,
                        
                            // Can specify path transition in more detail, or remove it entirely
                            // pathTransition: 'none',
                        
                            // Colors
                            pathColor: 'var(--bs-primary)',//`rgba(255, 145, 77, ${el.completion / 100})`,
                            textColor: 'var(--bs-primary)',//'#f88',
                            trailColor:'var(--bs-secondary)',
                            backgroundColor: '#fefefe',//'#3e98c7'
                        })}
                    />
                    </button> 
                })
            }
        </div>
        <ModalQAList part={partId} data ={props.data} show={modalShow}  onHide={() => setModalShow(false)}/>
    </div>);
    }
    else{
        return <></>
    }
}

export default HeptagonNav;