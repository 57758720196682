import { useState, useRef } from "react"

/*!
    \brief  ranking item. It does describe a ranking question
    \param  _nr         number of question
    \param  _question   question
    \param  _value      start or already entered value
    \param  _low        name/description of low point of ranking
    \param  _high       name/description of high point of ranking
*/
function    QuestionRanking( props ){
    const timerRef = useRef();
    const [RankingVal, setRankingVal] = useState( props.val);
    let _id = "qranking" + props.nr;

    const rankChange = (event)=>{
        const { value } = event.target;
        setRankingVal(value);
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            setRankingVal(value); // <-- re-enclose latest value
            console.log(value);
            props.updateData(props.nr,value);
        }, 250); // <-- tune this value to what feels best for you
    };
    
    return <div className='row'>
        <div className='col-lg-6'>
            {props.question}
        </div>
        <div className='col-lg-5 offset-lg-1'>
        <div className="d-flex justify-content-between">
                    <span>{props.low}</span>
                    <span>{props.high}</span>
            </div>
            <input  type="range" 
                    className=" form-range focus-ring-primary" 
                                min="0" max="10" step="1" 
                                id={_id} 
                                value={RankingVal} 
                                onChange={rankChange} >
            </input>
        </div>
    </div>
}
export default QuestionRanking;