/*
    upload excel file and convert
*/
import {useState, useRef, useContext} from 'react';
import {Form, Button, Alert} from 'react-bootstrap';

//import {urlUtilGetDynRESTAPI} from "../../helper/urlUtil";
import {convertExcel2Json } from "../../helper/digiCheckExcel";
import {ExcelContext} from '../../helper/ExcelProvider';

export const   getQFormFileInfoList = [
    {type:'hidden', text:'hidden'},
    {type:'success', text:'Kundendaten geladen'},
    {type:'warning', text:'server issue - check format'},
    {type:'danger', text:'Network problems'},
  ];

function    QFormFile(props){
    const {setCustomerSheets} = useContext(ExcelContext);

    const fullURL = window.location.href;
     
    const [file, setFile] = useState();
    const myFileRef = useRef(null);

    const uploader = async (_file) =>{
        try{
            let reader = new FileReader();

            reader.readAsArrayBuffer(_file);

            reader.onload = function() {
                console.log(reader.result);
                const base64String = btoa(String.fromCharCode(...new Uint8Array(reader.result)));
                let jsonObj = convertExcel2Json(base64String);
                console.log(jsonObj);
                setCustomerSheets(100,jsonObj);
                props.returnInfo(props.retObj, 1); 
            };
            
            reader.onerror = function() {
                console.log(reader.error);
                props.returnInfo(props.retObj, 2);
          };
        }
        catch(e){
            props.returnInfo(props.retObj, 3);  
        }
    }

    function handleChange(event) {
        setFile(event.target.files[0]);

        uploader(event.target.files[0]);
    }
    
    const chooseFile = () =>{
        if(myFileRef !== null){
            myFileRef.current.click();
        }
    }

    return <Form>
        <Form.Group controlId="formFile" className="mb-1">
            <Form.Control 
                type="file" 
                name="excel"
                onChange={handleChange} 
                className='visually-hidden'
                ref={myFileRef} />
        </Form.Group>
        <Button variant="primary" type="button" onClick={chooseFile}>
                Kunden Excel
        </Button>
    </Form>
}

export default QFormFile;