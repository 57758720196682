var digiCheck = {
    meta:[
        {
            CheckType:'Company',
            Version:'V1.0',
            Description:'Initialize'
        }
    ],
    customer:[
        {
            Company           : "42Fusion GmbH",
            Contact           : "Joe Doe"
        }
    ],
    modules:[
        {name:"Operation"        , completion:0, visible:true, ref:"part1", note:"", recommendation:""},
        {name:"Unternehmen"    , completion:0, visible:true, ref:"part2", note:"", recommendation:""},
        {name:"IT"        , completion:0, visible:true, ref:"part3", note:"", recommendation:""},
        {name:"Culture"  , completion:0, visible:true, ref:"part4", note:"", recommendation:""},
        {name:"Eco Sys"    , completion:0, visible:true, ref:"part5", note:"", recommendation:""},
        {name:"Leadership"  , completion:0, visible:true, ref:"part6", note:"", recommendation:""},
        {name:"Total"     , completion:0, visible:true, ref:"part7", note:"", recommendation:""},

    ],
    part1:[
        {
            Nr: 1,
            Frage: "Bist Du mit Deinem Mann zufrieden",
            FType: "scale",
            Value: -1,
            Answered: false
        },
        {
            Nr: 2,
            Frage: "Liebst Du Deinen Mann",
            FType: "yesno",
            Value: "none",
            Answered: false
          },
          {
            Nr: 3,
            Frage: "Was kann man an Deinem Mann verbessern",
            FType: "open",
            Value: "none",
            Answered: false
          },
          {
            Nr: 4,
            Frage: "Welche Eigenschaften hat Dein Mann",
            FType: "choice",
            Choice:"lieb, Aussehend, Vertrieb/Marketing, stark, einfühlsam, sportlich",
            Value: "none",
            Answered: false
          }
    ],
    part2:[
        {
            Nr: 1,
            Frage: "Zufriedenheitsskala Deiner Frau",
            FType: "scale",
            Value: -1,
            Answered: false
        }],
    part3:[
            {
                Nr: 1,
                Frage: "Bist Du im Part 2?",
                FType: "yesno",
                Value: "none",
                Answered: false
            }
        ],
    part4:[
                {
                    Nr: 1,
                    Frage: "Bist Du im Part 2?",
                    FType: "yesno",
                    Value: "none",
                    Answered: false
                }
            ],
    part5:[
            {
                Nr: 1,
                Frage: "Bist Du im Part 2?",
                FType: "yesno",
                Value: "none",
                Answered: false
            }
        ],
    part6:[
                {
                    Nr: 1,
                    Frage: "Bist Du im Part 2?",
                    FType: "yesno",
                    Value: "none",
                    Answered: false
                }
            ]
};

export default digiCheck;