import { useContext } from "react";

import { Button } from 'react-bootstrap';

import {convertJson2Excel} from '../../helper/digiCheckExcel';

import {ExcelContext} from '../../helper/ExcelProvider';
import {excelCheck} from '../../helper/hlpExcel';

//import xlsx from "json-as-xlsx"

//import withInfobar from "../Infobar";
/*let data = [
    {
      sheet: "Adults",
      columns: [
        { label: "User", value: "user" }, // Top level data
        { label: "Age", value: (row) => row.age + " years" }, // Custom format
        { label: "Phone", value: (row) => (row.more ? row.more.phone || "" : "") }, // Run functions
      ],
      content: [
        { user: "Andrea", age: 20, more: { phone: "11111111" } },
        { user: "Luis", age: 21, more: { phone: "12345678" } },
      ],
    },
    {
      sheet: "Children",
      columns: [
        { label: "User", value: "user" }, // Top level data
        { label: "Age", value: "age", format: '# "years"' }, // Column format
        { label: "Phone", value: "more.phone", format: "(###) ###-####" }, // Deep props and column format
      ],
      content: [
        { user: "Manuel", age: 16, more: { phone: 9999999900 } },
        { user: "Ana", age: 17, more: { phone: 8765432135 } },
      ],
    },
  ]
  
  let settings = {
    fileName: "100-Result", // Name of the resulting spreadsheet
    extraLength: 3, // A bigger number means that columns will be wider
    writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
    writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
    RTL: true, // Display the columns from right-to-left (the default value is false)
  }
  */
export const   getQFormResultInfoList = [
  {type:'hidden', text:'hidden'},
  {type:'success', text:'Kundendaten bereitgestellt'},
  {type:'danger', text:'converting failed'}
];

export function QFormResult(props){
  const {sheets} = useContext(ExcelContext);

    const onCompletedDownload = (sheet)=>{ 
      console.log("completed")
    }

    const onExcelDownload = ()=>{
      const _name = sheets.customer[0].companyId + '-' + sheets.meta[0].CheckType + '-' + sheets.meta[0].Version;
      props.returnInfo(props.retObj, 1);  
      //convertJson2Excel(sheets, {CheckType:sheets.meta[0].CheckType ,Version:sheets.meta[0].Version, Description:sheets.meta[0].Description, FirmenId:sheets.customer[0].FirmenId});
      excelCheck(sheets,_name, "dkelbch");
    }
    return <>
      <Button onClick={onExcelDownload}>Ergebnis</Button>
    </>
}
