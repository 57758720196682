import { useState } from "react"

function    QuestionChoice(props){
    const [choiceVal,setChoiceVal] = useState(props.val)
    let _id = "choice_" + props.nr;
   
    const isChecked = (name) => {
        if ( choiceVal !== undefined ){
            let idx = choiceVal.indexOf(name);
            if( idx !== -1){
                return true;
            }
        }
        
        return false;
    }

    const choiceChanged = (e) =>{
        const id = e.currentTarget.getAttribute("id");
        let newValues = " ";
        let info = id.split('_');

        //console.log(`nr ${info[1]} - attrib ${info[2]}`);

        if ( choiceVal !== undefined && choiceVal.indexOf(info[2]) >= 0 ){
            let arVal = choiceVal.split(',');
            for(let i=0; i < arVal.length; i++){
                if(arVal[i] === info[2]){
                    delete arVal[i];
                    break;
                }
            }
            newValues = arVal.join(',');
        }
        else{
            if( choiceVal !== undefined){
                let arVal = choiceVal.split(',')
                arVal.push(info[2]);
                newValues = arVal.join(',');
            }
            else{
                newValues = info[2];
            }
        }

        setChoiceVal(newValues);
        props.updateData(props.nr,newValues);
    }

    let _choiceList = props.choice.split(',').map((el,idx)=>{
        return (<div className="form-check form-check-inline" key={idx}>
                    <input className="form-check-input" type="checkbox" id={_id+'_'+el} checked={isChecked(el)} onChange={choiceChanged} />
                    <label className="form-check-label" >{el}</label>
        </div>)
    });

    let rows = _choiceList.reduce(function (rows, key, index) { 
        return (index % 2 === 0 ? rows.push([key]) 
          : rows[rows.length-1].push(key)) && rows;
      }, []);
   
    return <div className='row'>
        <div className='col-lg-6'>
        {props.question}                
        </div>
        <div className='col-lg-5 offset-lg-1'>
        {rows.map((row,idx) => ( 
            <div className="row" key={idx} >
            { row.map((col,idx) => (<div className="col-lg-6" key={idx} >{col}</div>)) }
            </div>
        ))}
        </div>
    </div> 
}

export default  QuestionChoice;