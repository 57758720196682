// Providing Context - qform
// ==================

import React, {useState, useEffect} from "react";

import testData from '../testdata';

const ExcelContext = React.createContext()

function ExcelProvider({children}) {
    const [sheets, setSheets] = useState(testData);
    const [isUpdated, setIsUpdated] = useState(false);
    // Local Storage: setting & getting data
    useEffect(() => {
        const tstSheet = localStorage.getItem('sheets');

        if( tstSheet !== "undefined"){
        const sheetsData = JSON.parse(localStorage.getItem('sheets'))
        
                if (sheetsData) {
                setSheets(sheetsData)
            }
        }
    }, [])
    
    useEffect(() => {
        localStorage.setItem('sheets', JSON.stringify(sheets))
    }, [sheets])
    
    function setCustomerSheets(_customerID, _jsonSheets){
        setSheets(_jsonSheets);
        setIsUpdated(true);
    }
    
    function setDefaultSheets(){
        setSheets(testData);
    }

    function clearIsUpdated(){
        setIsUpdated(false);
    }
    return (
        <ExcelContext.Provider value={{setCustomerSheets, setDefaultSheets, sheets, clearIsUpdated, isUpdated}}>
            {children}
        </ExcelContext.Provider>
    )
}

export {ExcelProvider, ExcelContext}
