import { useState, useRef } from "react"

function    QuestionYesNoMatter(props){
    const [isCheckedYes, setIsCheckedYes] = useState(false | props.val === "yes");
    const [isCheckedNo, setIsCheckedNo] = useState(false | props.val === "no");
    const [isCheckedMatter, setIsCheckedMatter] = useState(false | props.val === "matter");
  
    let _id = "qyesnomatter" + props.nr;

    const onChange = (event) => {
        const id = event.currentTarget.getAttribute("id");
        switch(id){
            case _id+'-y':
                setIsCheckedYes(true);
                setIsCheckedNo(false);
                setIsCheckedMatter(false);
                // update parent
                props.updateData(props.nr,"yes");
            break;
            case _id+'-n':
                setIsCheckedYes(false);
                setIsCheckedNo(true);
                setIsCheckedMatter(false);
                // update parent
                props.updateData(props.nr,"no");
            break;
            case _id+'-m':
                setIsCheckedYes(false);
                setIsCheckedNo(false);
                setIsCheckedMatter(true);
                // update parent
                props.updateData(props.nr,"matter");
            break;
            default:
                setIsCheckedYes(false);
                setIsCheckedNo(false);
                setIsCheckedMatter(false);
                // update parent
                props.updateData(props.nr,"none");
            break;
        }
    }
    return <div className='row'>
        <div className='col-lg-6'>
        {props.question}                
        </div>
        <div className='col-lg-5 offset-lg-1'>
        <div className="form-check form-check-inline" key="1">
            <input className="form-check-input" type="checkbox" id={_id+'-y'} checked={isCheckedYes} onChange={onChange}/>
            <label className="form-check-label" >Ja</label>
        </div>
        <div className="form-check form-check-inline" key="2">
            <input className="form-check-input" type="checkbox" id={_id+'-n'} checked={isCheckedNo} onChange={onChange}/>
            <label className="form-check-label" >Nein</label>
        </div> 
        <div className="form-check form-check-inline" key="3">
            <input className="form-check-input" type="checkbox" id={_id+'-m'} checked={isCheckedMatter} onChange={onChange}/>
            <label className="form-check-label" >Vielleicht</label>
        </div>
        </div> 
         
    </div>
}

export default QuestionYesNoMatter;