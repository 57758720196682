import React from 'react';
import { createBrowserRouter,Outlet, Navigate} from "react-router-dom";

import Header from './components/Header';
import PageCheck from './pages/PageCheck';

const Layout = () => (
    <div className='container-fluid'>
      <Header />
      <Outlet />   
    </div>
  );

  const router = createBrowserRouter([
    {
        element: <Layout />,
        children: [
        {
            path: "/",
            element:<PageCheck />
        }
      ]
    }
  ]);

export default router;